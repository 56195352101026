// Defining variables
$sidebar-width: 170px;
$navbar-height: 80px;
$layout-gap: 1px;
$main-content-margin: 4px;
$main-content-padding: 20px;
$main-content-padding-bottom: 0px;
$main-content-max-height: calc(100vh - #{$navbar-height} - #{$layout-gap} - #{$main-content-padding} - #{$main-content-padding-bottom} - #{$main-content-margin} - #{$main-content-margin});

.layout {
  display: grid;
  gap: $layout-gap;
  grid-template-columns: $sidebar-width 1fr;
  grid-template-rows: $navbar-height 1fr;
  grid-template-areas: "sidebar navbar" "sidebar main";
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @apply bg-gray-lightest;
}
.navbar {
  grid-area: navbar;
  @apply bg-white shadow-sm;
}
.sidebar {
  grid-area: sidebar;
  @apply bg-white shadow-sm;
}
.main {
  grid-area: main;
  margin: $main-content-margin;
  padding: $main-content-padding;
  padding-bottom: $main-content-padding-bottom;
  @apply relative bg-white shadow-lg;
  .main-content {
    max-height: $main-content-max-height;
  }
  overflow-x: hidden;
  overflow-y: hidden;
}