/* En tamaños inferiores a laptop, los textos reducen su tamaño un 20% */

.childspan-blue-darkest > span {
  @apply text-blue-darkest;
}
.childspan-blue-dark > span {
  @apply text-blue-dark;
}
.childspan-blue > span {
  @apply text-blue;
}
.childspan-blue-light > span {
  @apply text-blue-light;
}
.childspan-gray-darkest > span {
  @apply text-gray-darkest;
}
.childspan-gray-dark > span {
  @apply text-gray-dark;
}
.childspan-gray > span {
  @apply text-gray;
}
.childspan-gray-light > span {
  @apply text-gray-light;
}
.childspan-gray-lightest > span {
  @apply text-gray-lightest;
}
.childspan-white > span {
  @apply text-white;
}
.childspan-black > span {
  @apply text-black;
}
.childspan-error > span {
  @apply text-error;
}
