// --input-color: var(--primary);
// --input-border-radius: 5px;
// --input-height: 35px;
// --input-border: 1px solid var(--blue);
// --input-padding: 0rem 0.8rem;
// --input-focus-border: 1px solid var(--blue-lightest);
// --input-error: var(--error);
// --input-invalid-border: 1px solid var(--error);
// --input-error-placeholder: var(--error);

.select[multiple] {
  padding: var(--input-padding);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  color: var(--input-color);
  min-height: calc(var(--input-height) * 2.5);
  &:focus:not(.invalid) {
    border: var(--input-focus-border);
    outline: none;
  }
}

.invalid {
  color: var(--input-error);
  border: var(--input-invalid-border);
  &::placeholder {
    color: var(--input-error-placeholder);
  }
}