/* font sizes
  14px, 16px, 18ps, 20px, 24px, 34px, 40px, 48px, 52px, 80px
*/

.childspan-blue-darkest > span {
  @apply text-blue-darkest;
}
.childspan-blue-dark > span {
  @apply text-blue-dark;
}
.childspan-blue > span {
  @apply text-blue;
}
.childspan-blue-light > span {
  @apply text-blue-light;
}
.childspan-blue-lightest > span {
  @apply text-blue-lightest;
}
.childspan-gray-darkest > span {
  @apply text-gray-darkest;
}
.childspan-gray-dark > span {
  @apply text-gray-dark;
}
.childspan-gray > span {
  @apply text-gray;
}
.childspan-gray-light > span {
  @apply text-gray-light;
}
.childspan-gray-lightest > span {
  @apply text-gray-lightest;
}
.childspan-white > span {
  @apply text-white;
}
.childspan-black > span {
  @apply text-black;
}
.childspan-error > span {
  @apply text-error;
}


.Paragraph {}